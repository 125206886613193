import {
  getReport1,
  getReport2,
  getReport3,
  getReport4,
  getReport5,
  getReport6,
  getReport8,
  getReport9,
  getReport7,
  getReport10,
  downloadReport1,
  downloadReport2,
  downloadReport3,
  downloadReport4,
  downloadReport5,
  downloadReport6,
  downloadReport7,
  downloadReport8,
  downloadReport9,
  downloadReport10,
  getReport11,
  downloadReport11,
  getReport12,
  downloadReport12,
  getReport13,
  downloadReport13
  } from "../../../services/RequestService";

export async function getReports(filterType, filter, setState, onAddAlert) {
  const workGroupId = filter?.cellId;
  const processTypeId = filter?.processTypeId;
  const processStatusId = filter?.processStatusId;
  const processActionId = filter?.processActionId;
  const startDate = filter?.startDate;
  const endDate = filter?.endDate;
  const userId = filter?.userId;
  const finished = filter?.finished;
  const month = filter?.month;
  const year = filter?.year;

  if (filterType === "Relatório de vendas") {
    getReport1({ processTypeId, startDate, endDate, workGroupId }).then(
      (res) => {
        if (res.ok) {
          let headerTitles = getHeaderTitles(res.data);
          setState({ allReports: res.data, headerTitles, loading: false });
          addAlert(res, onAddAlert, "filter");
        } else {
          setState({ loading: false });
          addAlert(res, onAddAlert, "filter");
        }
      }
    );
  } else if (filterType === "Relatório de propostas enviadas") {
    getReport2({
      processTypeId,
      begin: startDate,
      end: endDate,
      workGroupId,
      userId,
    }).then((res) => {
      if (res.ok) {
        setStateReport(res, setState, onAddAlert);
      } else {
        setStateReportError(res, setState, onAddAlert);
      }
    });
  } else if (filterType === "Relatório de propostas aprovadas") {
    getReport3({
      processTypeId,
      begin: startDate,
      end: endDate,
      workGroupId,
      userId,
    }).then((res) => {
      if (res.ok) {
        setStateReport(res, setState, onAddAlert);
      } else {
        setStateReportError(res, setState, onAddAlert);
      }
    });
  } else if (filterType === "Relatório de contratos enviados") {
    getReport4({
      processTypeId,
      begin: startDate,
      end: endDate,
      workGroupId,
      userId,
    }).then((res) => {
      if (res.ok) {
        setStateReport(res, setState, onAddAlert);
      } else {
        setStateReportError(res, setState, onAddAlert);
      }
    });
  } else if (filterType === "Relatório de contratos assinados") {
    getReport5({
      processTypeId,
      begin: startDate,
      end: endDate,
      workGroupId,
      userId,
    }).then((res) => {
      if (res.ok) {
        setStateReport(res, setState, onAddAlert);
      } else {
        setStateReportError(res, setState, onAddAlert);
      }
    });
  } else if (filterType === "Relatório de recebimentos") {
    getReport6({
      begin: startDate,
      end: endDate,
      workGroupId,
      userId,
    }).then((res) => {
      if (res.ok) {
        setStateReport(res, setState, onAddAlert);
        console.log("REPORT 6", res);
      } else {
        setStateReportError(res, setState, onAddAlert);
      }
    });
  } else if (filterType === "Relatório de pagamento credenciado") {
    getReport7({
      month: month,
      year: year,
      workGroupId,
    }).then((res) => {
      if (res.ok) {
        setStateReport(res, setState, onAddAlert);
      } else {
        setStateReportError(res, setState, onAddAlert);
      }
    });
  } else if (filterType === "Relatório de cadastro de credenciados") {
    getReport8({
      begin: startDate,
      end: endDate,
      userId,
    }).then((res) => {
      if (res.ok) {
        setStateReport(res, setState, onAddAlert);
      } else {
        setStateReportError(res, setState, onAddAlert);
      }
    });
  } else if (filterType === "Relatório de cadastro de usuários") {
    getReport9({
      begin: startDate,
      end: endDate,
    }).then((res) => {
      if (res.ok) {
        setStateReport(res, setState, onAddAlert);
      } else {
        setStateReportError(res, setState, onAddAlert);
      }
    });
  } else if (filterType === "Relatório de Status dos Processos") {
    getReport10({
      begin: startDate,
      end: endDate,
      processStatusId,
      processTypeId,
    }).then((res) => {
      if (res.ok) {
        setStateReport(res, setState, onAddAlert);
      } else {
        setStateReportError(res, setState, onAddAlert);
      }
    });
  } else if (filterType === "Relatório de Processos Fechados") {
    getReport11({ begin: startDate, end: endDate, workGroupId }).then((res) => {
      if (res.ok) {
        setStateReport(res, setState, onAddAlert);
      } else {
        setStateReportError(res, setState, onAddAlert);
      }
    });
  } else if (filterType === "Agenda dos Usuários") {
    getReport12({
      begin: startDate,
      end: endDate,
      processTypeId,
      actionTyperId: processActionId,
      finished,
      userId,
    }).then((res) => {
      if (res.ok) {
        setStateReport(res, setState, onAddAlert);
      } else {
        setStateReportError(res, setState, onAddAlert);
      }
    });
  } else if (filterType === "Prazos Suspensos, Processos e Justificativas") {
    getReport13({
      beginSuspended: startDate,
      endSuspended: endDate,
      processTypeId,
    }).then((res) => {
      if (res.ok) {
        setStateReport(res, setState, onAddAlert);
      } else {
        setStateReportError(res, setState, onAddAlert);
      }
    });
  } else {
    setState({ loading: false });
    let res = [];
    addAlert(res, onAddAlert, "filter");
  }
}

function setStateReport(res, setState, onAddAlert) {
  let headerTitles = getHeaderTitles(res.data);
  setState({ allReports: res.data, headerTitles, loading: false });
  addAlert(res, onAddAlert, "filter");
}

function setStateReportError(res, setState, onAddAlert) {
  setState({ loading: false });
  addAlert(res, onAddAlert, "filter");
}

export async function getFileReport(filterType, filter, setState, onAddAlert) {
  const workGroupId = filter?.cellId;
  const processTypeId = filter?.processTypeId;
  const processStatusId = filter?.processStatusId;
  const processActionId = filter?.processActionId;
  const startDate = filter?.startDate;
  const endDate = filter?.endDate;
  const userId = filter?.userId;
  const finished = filter?.finished;
  if (
    filterType === "Relatório de vendas" &&
    processTypeId !== "" &&
    startDate !== "" &&
    endDate !== "" &&
    workGroupId !== ""
  ) {
    downloadReport1({ processTypeId, startDate, endDate, workGroupId }).then(
      (res) => {
        if (res.ok) {
          setState({ file: res.data, loading: false });
          addAlert(res, onAddAlert, "file");
        } else {
          setState({ loading: false });
          addAlert(res, onAddAlert, "file");
        }
      }
    );
  } else if (
    filterType === "Relatório de propostas enviadas" &&
    processTypeId !== "" &&
    startDate !== "" &&
    endDate !== "" &&
    workGroupId !== "" &&
    userId !== ""
  ) {
    downloadReport2({
      processTypeId,
      begin: startDate,
      end: endDate,
      workGroupId,
      userId,
    }).then((res) => {
      if (res.ok) {
        setState({ file: res.data, loading: false });
        addAlert(res, onAddAlert, "file");
      } else {
        setState({ loading: false });
        addAlert(res, onAddAlert, "file");
      }
    });
  } else if (
    filterType === "Relatório de propostas aprovadas" &&
    processTypeId !== "" &&
    startDate !== "" &&
    endDate !== "" &&
    workGroupId !== "" &&
    userId !== ""
  ) {
    downloadReport3({
      processTypeId,
      begin: startDate,
      end: endDate,
      workGroupId,
      userId,
    }).then((res) => {
      if (res.ok) {
        setState({ file: res.data, loading: false });
        addAlert(res, onAddAlert, "file");
      } else {
        setState({ loading: false });
        addAlert(res, onAddAlert, "file");
      }
    });
  } else if (
    filterType === "Relatório de contratos enviados" &&
    processTypeId !== "" &&
    startDate !== "" &&
    endDate !== "" &&
    workGroupId !== "" &&
    userId !== ""
  ) {
    downloadReport4({
      processTypeId,
      begin: startDate,
      end: endDate,
      workGroupId,
      userId,
    }).then((res) => {
      if (res.ok) {
        setState({ file: res.data, loading: false });
        addAlert(res, onAddAlert, "file");
      } else {
        setState({ loading: false });
        addAlert(res, onAddAlert, "file");
      }
    });
  } else if (
    filterType === "Relatório de contratos assinados" &&
    processTypeId !== "" &&
    startDate !== "" &&
    endDate !== "" &&
    workGroupId !== "" &&
    userId !== ""
  ) {
    downloadReport5({
      processTypeId,
      begin: startDate,
      end: endDate,
      workGroupId,
      userId,
    }).then((res) => {
      if (res.ok) {
        setState({ file: res.data, loading: false });
        addAlert(res, onAddAlert, "file");
      } else {
        setState({ loading: false });
        addAlert(res, onAddAlert, "file");
      }
    });
  } else if (
    filterType === "Relatório de recebimentos" &&
    startDate !== "" &&
    endDate !== "" &&
    workGroupId !== "" &&
    userId !== ""
  ) {
    downloadReport6({
      begin: startDate,
      end: endDate,
      workGroupId,
      userId,
    }).then((res) => {
      if (res.ok) {
        setState({ file: res.data, loading: false });
        addAlert(res, onAddAlert, "file");
      } else {
        setState({ loading: false });
        addAlert(res, onAddAlert, "file");
      }
    });
  } else if (
    filterType === "Relatório de pagamento credenciado" &&
    startDate !== "" &&
    workGroupId !== ""
  ) {
    const date = startDate.split("-");
    downloadReport7({
      month: date[1],
      year: date[0],
      workGroupId,
    }).then((res) => {
      if (res.ok) {
        setState({ file: res.data, loading: false });
        addAlert(res, onAddAlert, "file");
      } else {
        setState({ loading: false });
        addAlert(res, onAddAlert, "file");
      }
    });
  } else if (
    filterType === "Relatório de cadastro de credenciados" &&
    startDate !== "" &&
    endDate !== "" &&
    userId !== ""
  ) {
    downloadReport8({
      begin: startDate,
      end: endDate,
      userId,
    }).then((res) => {
      if (res.ok) {
        setState({ file: res.data, loading: false });
        addAlert(res, onAddAlert, "file");
      } else {
        setState({ loading: false });
        addAlert(res, onAddAlert, "file");
      }
    });
  } else if (
    filterType === "Relatório de cadastro de usuários" &&
    startDate !== "" &&
    endDate !== ""
  ) {
    downloadReport9({
      begin: startDate,
      end: endDate,
    }).then((res) => {
      if (res.ok) {
        setState({ file: res.data, loading: false });
        addAlert(res, onAddAlert, "file");
      } else {
        setState({ loading: false });
        addAlert(res, onAddAlert, "file");
      }
    });
  } else if (
    filterType === "Relatório de Status dos Processos" &&
    startDate !== "" &&
    endDate !== "" &&
    processStatusId !== ""
  ) {
    downloadReport10({ begin: startDate, end: endDate, processStatusId }).then(
      (res) => {
        if (res.ok) {
          setState({ file: res.data, loading: false });
          addAlert(res, onAddAlert, "file");
        } else {
          setState({ loading: false });
          addAlert(res, onAddAlert, "file");
        }
      }
    );
  } else if (
    filterType === "Relatório de Processos Fechados" &&
    startDate !== "" &&
    endDate !== "" &&
    workGroupId !== ""
  ) {
    downloadReport11({ begin: startDate, end: endDate, workGroupId }).then(
      (res) => {
        if (res.ok) {
          setState({ file: res.data, loading: false });
          addAlert(res, onAddAlert, "file");
        } else {
          setState({ loading: false });
          addAlert(res, onAddAlert, "file");
        }
      }
    );
  } else if (
    filterType === "Agenda dos Usuários" &&
    startDate !== "" &&
    endDate !== "" &&
    processTypeId !== "" &&
    processActionId !== "" &&
    finished
  ) {
    downloadReport12({
      begin: startDate,
      end: endDate,
      processTypeId,
      actionTyperId: processActionId,
      finished,
    }).then((res) => {
      if (res.ok) {
        setState({ file: res.data, loading: false });
        addAlert(res, onAddAlert, "file");
      } else {
        setState({ loading: false });
        addAlert(res, onAddAlert, "file");
      }
    });
  } else if (
    filterType === "Prazos Suspensos, Processos e Justificativas" &&
    processTypeId !== "" &&
    startDate !== "" &&
    endDate !== ""
  ) {
    downloadReport13({
      begin: startDate,
      end: endDate,
      processTypeId,
      actionTyperId: processActionId,
      finished,
    }).then((res) => {
      if (res.ok) {
        setState({ file: res.data, loading: false });
        addAlert(res, onAddAlert, "file");
      } else {
        setState({ loading: false });
        addAlert(res, onAddAlert, "file");
      }
    });
  } else {
    setState({ loading: false });
    let res = [];
    addAlert(res, onAddAlert, "file");
  }
}

function addAlert(res, onAddAlert, from) {
  if (res?.ok) {
    if (res.data.length !== 0) {
      onAddAlert("Dados carregados com sucesso", "success");
    } else {
      onAddAlert(
        "Não há informações existentes com os filtros selecionados.",
        "warning"
      );
    }
  } else {
    onAddAlert("Ocorreu um erro ao tentar gerar o relatório.", "danger");
  }
}

function getHeaderTitles(data) {
  console.log(data[0]);
  const titles = {
    amountToBeReceived: "VALOR A RECEBER",
    dateOfInvoiceIssued: "DATA DE EMISSÃO DA NF",
    dateOfReceiveOfServiceContract:
      "DATA DE RECEBIMENTO DO CONTRATO DE SERVIÇO",
    paymentMethod: "FORMA DE PAGAMENTO",
    customerName: "NOME DO CLIENTE",
    workGroupName: "GRUPO DE TRABALHO",
    currentActionResponsible: "USUÁRIO RESPONSÁVEL PELO ANDAMENTO",
    userName: "USUÁRIO RESPONSÁVEL PELO ATENDIMENTO",
    contactStartDate: "INÍCIO DE CONTATO",
    channel: "CANAL",
    processTypeName: "TESE",
    processStatusName: "ANDAMENTO DO PROCESSO",
    parcelQuantity: "QUANTIDADE DE PARCELAS",
    receivedAmount: "VALOR RECEBIDO",
    dateOfSubmissionOfServiceProposal: "DATA DE ENVIO DA PROPOSTA",
    dateOfContractSignature: "DATA DE ASSINATURA DO CONTRATO",
    contractSigningDate: "DATA DE ASSINATURA DO CONTRATO",
    expirationDays: "EXPIRA PRAZO EM",
    isPaused: "PAUSADO",
    overdueAmount: "VALOR EM ATRASO",
    invoiceAmount: "COBRANÇA TOTAL",
    username: "NOME DO USUÁRIO",
    email: "E-MAIL",
    privileges: "PRIVILÉGIOS",
    cellName: "CÉLULA",
    registeredBy: "USUÁRIO RESPONSÁVEL PELO CADASTRO",
    outsourceName: "CREDENCIADO",
    prospectorName: "PROSPECTOR",
    city: "CIDADE",
    state: "ESTADO",
    outsourceEmail: "E-MAIL DO CREDENCIADO",
    outsourceTelephone: "TELEFONE DO CREDENCIADO",
    dateOfReceiveOfServiceProposal: "DATA DE ACEITE DA PROPOSTA",
    responsibleForTaskExecution: "USUÁRIO RESPONSÁVEL PELA EXECUÇÃO DA TAREFA",
    daysCount: "TOTAL DE DIAS",
    actionTypeName: "TIPO DE ANDAMENTO",
    deadlineForExecutionProgress: "PRAZO PARA EXECUÇÃO DO ANDAMENTO",
    overdue: "ATRASADO",
    dateOfProcessDone: "DATA DE ENCERRAMENTO DO PROCESSO",
    workCellName: "CÉLULA",
    scenarioName: "CENÁRIO ESCOLHIDO",
    ppconclusionDate: "DATA DE CONCLUSÃO DO PLANEJAMENTO PREVIDENCIÁRIO",
    chosenDate: "DATA DE APOSENTADORIA ESCOLHIDA",
    numberOfMessagesSent: "NÚMERO DE MENSAGENS ENVIADAS",
    deadlineForExecutionProcess: "PRAZO PARA EXECUÇÃO ANDAMENTO",
    suspensionDate: "DATA DE SUSPENSÃO",
    daysTotal: "CONTADOR DIAS TOTAL",
    justifications: "JUSTIFICATIVAS",
    responsibleName: "RESPONSÁVEL PELA EXECUÇÃO DA TAREFA",
    period: "PERÍODO",
    comissionValue: "VALOR DA COMISSÃO",
    finished: "CONCLUÍDO",
    processType: "TESE",
  };

  let headerTitles = [];

  if (data.length !== 0) {
    Object.entries(data[0]).forEach((element) => {
      headerTitles.push(titles[element[0]]);
    });
  }

  return headerTitles;
}
