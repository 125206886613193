import React, { Component } from "react";
import {
  getUserByPagination,
  getUserBySearchBar,
  getUserCount,
} from "../../../services/RequestService";
import "./UserPage.css";
import ModalUser from "../../../components/settings/user/ModalUser";
import ModalDeleteUser from "../../../components/settings/user/ModalDeleteUser";
import {
  Header,
  Loading,
  PageContainer,
  TableComponentWithPagination,
} from "../../../genericComponents";
import { masks } from "../../../utils";

class UserPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [],
      filterValue: "",
      show: false,
      showDelete: false,
      user: null,
      data: undefined,
      loading: true,
      totalItems: 0,
      fixedTotalItems: 0,
      totalPages: 0,
      page: 0,
    };
  }

  componentDidMount = () => {
    this.setState({ loading: true });
    this.getByFilter();
  };

  getByFilter = () => {
    const { filterValue, page } = this.state;
    getUserBySearchBar({ searchItem: filterValue, page }).then((res) => {
      if (res.ok) {
        this.handleUserChange(res.data);
      }
    });
    getUserCount({ searchItem: filterValue }).then((res) => {
      if (res.ok) {
        this.setState({ totalItems: res.data });
      }
    });
  };

  pagination = (pageNumber) => {
    this.setState({ loading: true });
    getUserByPagination({
      search_item: this.state.filterValue,
      page: pageNumber - 1,
    }).then((res) => {
      if (res.ok) {
        this.handleUserChange(res.data);
      }
    });
  };

  handleUserChange = (data) => {
    this.setState({
      list: data.content
        .map((item) => ({
          id: item.id,
          name: item.name,
          email: item.email,
          cpf: item.cpf,
          status: item.active ? "Ativo" : "Inativo",
          cell: item.cellName,
        }))
        .sort((a, b) => (a.id > b.id ? 1 : -1)),
      page: data.currentPage,
      totalPages: data.totalPages,
      fixedTotalItems: data.fixedTotalItems,
      loading: false,
    });
  };

  handleInputChange = (e) => {
    e.persist();
    this.setState((state) => ({ ...state, [e.target.name]: e.target.value }));
  };

  handleSearch = (e) => {
    if (e.key === "Enter") {
      this.getByFilter();
    }
  };

  setShowModal = (show) => {
    this.setState((state) => ({ ...state, show, data: undefined }));
    if (!show) {
      this.componentDidMount();
    }
  };

  setShowModalDelete = (showDelete, user, wasUpdated) => {
    this.setState((state) => ({ ...state, showDelete, user }));
    if (!showDelete && wasUpdated) {
      this.componentDidMount();
    }
  };

  setModalForEditing = (i) => {
    this.setState((state) => ({ ...state, data: i, show: true }));
  };

  filter = (properties) => (item) => {
    const { filterValue } = this.state;
    for (const property of properties) {
      if (
        item[property] &&
        item[property].toUpperCase().includes(filterValue.toUpperCase())
      ) {
        return true;
      }
    }
    return false;
  };

  render() {
    const {
      filterValue,
      show,
      data,
      showDelete,
      user,
      loading,
      totalItems,
      totalPages,
      page,
      fixedTotalItems,
      list,
    } = this.state;
    return (
      <Loading loading={loading}>
        <Header
          active="Usuários"
          logged
          filter={{
            type: "text",
            className: "text-left",
            placeholder: "Buscar por ... ",
            name: "filterValue",
            value: filterValue,
            onChange: this.handleInputChange,
            onKeyPress: this.handleSearch,
            onClick: () => this.getByFilter(),
          }}
          buttons={[
            this.props.privileges !== undefined &&
            this.props.privileges["configuracoes/usuarios"].create
              ? {
                  onClick: () => this.setShowModal(true),
                  icon: "fas fa-plus-circle",
                  label: "Adicionar",
                }
              : null,
          ]}
        />
        <PageContainer fixedHeight className="p-3">
          <TableComponentWithPagination
            loading={loading}
            countLabel="usuários"
            countLabelSingular="usuário"
            startSorted="id"
            header={[
              { label: "#", sortProperty: "id" },
              { label: "Nome", sortProperty: "name" },
              { label: "Email", sortProperty: "email" },
              { label: "CPF", sortProperty: "cpf" },
              { label: "Status", sortProperty: "status" },
              { label: "Célula", sortProperty: "cell" },
              this.props.privileges !== undefined &&
              this.props.privileges["configuracoes/usuarios"].delete
                ? { label: "Excluir" }
                : null,
            ]}
            totalItems={totalItems}
            fixedTotalItems={fixedTotalItems}
            totalPages={totalPages}
            page={page + 1}
            list={list}
            onItemClick={(itemList) => {
              if (itemList.id === 1) {
                return;
              }
              const item = itemList;
              this.setModalForEditing(item);
            }}
            forEach={(item) => [
              { label: item.id },
              { label: item.name },
              { label: item.email },
              { label: item.cpf ? masks.CPF(item.cpf) : item.cpf },
              {
                label: (
                  <div
                    className={
                      item.status === "Ativo"
                        ? "status-enable"
                        : "status-inactive"
                    }
                  >
                    {item.status}
                  </div>
                ),
              },
              { label: item.cell },
              this.props.privileges !== undefined &&
              this.props.privileges["configuracoes/usuarios"].delete
                ? {
                    onClick:
                      item.id === 1
                        ? null
                        : () => this.setShowModalDelete(true, item),
                    label: (
                      <div className="text-center">
                        {item.id !== 1 && (
                          <i className="fas fa-trash-alt is-required"></i>
                        )}
                      </div>
                    ),
                  }
                : null,
            ]}
            onChangePage={this.pagination}
          />

          {show && (
            <ModalUser
              show={show}
              onHide={() => this.setShowModal(false)}
              data={data && data}
            />
          )}
          {showDelete && (
            <ModalDeleteUser
              show={showDelete}
              onHide={(wasUpdated) =>
                this.setShowModalDelete(false, null, wasUpdated)
              }
              user={user}
            />
          )}
        </PageContainer>
      </Loading>
    );
  }
}

export default UserPage;
