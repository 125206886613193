import React, { Component } from "react";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import "../../../pages/calendar/CalendarPage.css";
import { getAllProcessInProgress } from "../../../services/RequestService";
import { Link } from "react-router-dom";
import { FullHeightCard } from "../../../genericComponents";

class CardProcessComp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      process: [],
      showModal: false,
      oneProcess: undefined,
      page: 0,
      hasMore: true,
      loading: false,
    };
  }

  componentDidMount = () => {
    this.loadActions();
  };

  loadActions = () => {
    const { page, process, hasMore, loading } = this.state;
    if (hasMore && !loading) {
      this.setState({ loading: true });
      getAllProcessInProgress(page)
        .then((res) => {
          const d = Array.isArray(res.data) ? res.data : [];
          this.setState({
            process: process.concat(d),
            page: page + 1,
            hasMore: d.length > 0,
            loading: false,
          });
        })
        .catch((error) => console.log("Erro action", error))
        .finally(() => {
          this.props.subtractLoading();
        });
    }
  };

  getOwer = (owner) => {
    if (owner === null) {
      return "Responsável a definir";
    }
    return owner.name;
  };

  getProcessType = (processType) => {
    if (processType === null) {
      return "Tipo da tese a definir";
    }

    return processType.name;
  };

  getLocateDateString = (date) => {
    var cdDate = new Date(date);
    if (date === null) {
      return "Não definido";
    }
    return cdDate.toLocaleDateString("pt-BR");
  };

  chooseProcess = (i) => {
    this.setState((state) => ({ ...state, oneProcess: i, showModal: true }));
  };

  setShowModal = (showModal) => {
    this.setState((state) => ({ ...state, showModal }));
    if (!showModal) {
      this.setState((state) => ({ ...state, oneProcess: undefined }));
      this.componentDidMount();
    }
  };

  getDeadlineDays = (data) => {
    let nowDate = new Date().getTime();
    if (data.deliveryDate === null) {
      return "Não definido";
    }
    let deliveryDate = new Date(data.deliveryDate).getTime();

    let diffTime = deliveryDate - nowDate;
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays < 0) {
      return 0;
    }
    return diffDays;
  };

  getBoardColor = (data) => {
    let diffDays = this.getDeadlineDays(data);

    if (data.done) {
      return "action-success-color-border";
    } else if (diffDays > 10) {
      return "action-warning-color-border";
    } else if (diffDays >= 0) {
      return "action-attention-color-border";
    } else if (diffDays < 0) {
      return "action-danger-color-border";
    }
  };

  render() {
    const { process, loading } = this.state;
    return (
      <FullHeightCard
        handleScroll={this.loadActions}
        pageContainerChild
        xs={12}
        xl={4}
        header={
          <Row className="mx-0 mt-2 px-3 pt-3">
            <Col className="p-0 text-left">
              <span>Processos</span>
            </Col>
            <Col className="p-0 text-right">
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>{process && process.length} processos</Tooltip>
                }
              >
                <div className="d-inline insert-icon">
                  <i className="far fa-clone mr-1" />
                  <span>{process && process.length}</span>
                </div>
              </OverlayTrigger>
            </Col>
          </Row>
        }
      >
        {process !== undefined &&
          process !== null &&
          process.length > 0 &&
          process.map((proc) => {
            return (
              <Link
                key={proc.id}
                to={"/processo/" + proc.id + "/agenda"}
                style={{ color: "black", textDecoration: "none" }}
              >
                <div
                  key={proc.id}
                  className={
                    "process-card process-color-border p-3 m-2 rounded " +
                    this.getBoardColor(proc)
                  }
                >
                  <Row>
                    <Col className="m-0 ml-1">
                      <Row>
                        <small>Cliente</small>
                      </Row>
                      <Row>
                        <h6>
                          {proc.customerName !== undefined && proc.customerName}
                        </h6>
                      </Row>

                      <Row>
                        <small>Tipo da tese</small>
                      </Row>
                      <Row>
                        <span>
                          {proc.processTypeName !== undefined &&
                            this.getProcessType(proc.processTypeName)}
                        </span>
                      </Row>

                      <Row>
                        <small>Responsável</small>
                      </Row>

                      <Row>
                        <span>
                          {proc.cellName !== undefined &&
                            this.getOwer(proc.cellName)}
                        </span>
                      </Row>
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip>Ações realizadas / Total de ações</Tooltip>
                      }
                    >
                      <Col className="p-0 text-center">
                        <i className="fas fa-check d-block" />
                        <small>
                          {proc.actionsDone} / {proc.actionsLength}
                        </small>
                      </Col>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip>Prazo de entrega</Tooltip>}
                    >
                      <Col className="p-0 text-center">
                        <i className="fas fa-calendar-day d-block" />
                        <small>
                          {this.getLocateDateString(proc.deliveryDate)}
                        </small>
                      </Col>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip>Tempo restante</Tooltip>}
                    >
                      <Col className="p-0 text-center">
                        <i className="fas fa-bomb  d-block"></i>
                        <small>
                          {proc.paused
                            ? "Processo pausado"
                            : this.getDeadlineDays(proc)}
                        </small>
                      </Col>
                    </OverlayTrigger>
                  </Row>
                </div>
              </Link>
            );
          })}
        <div className={"p-3 text-center"} style={{ minHeight: "50px" }}>
          {process.length <= 0 && <span>Você não possui nenhum processo</span>}
          {loading && <span>Carregando...</span>}
        </div>
      </FullHeightCard>
    );
  }
}

export default CardProcessComp;
