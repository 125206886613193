import React, { useState } from "react";
import { Row } from "react-bootstrap";
import { InputName, InputCPF, InputEmail, RadioGroup, InputCNPJ } from "../../../genericComponents";
import { isCPFValid, isEmailValid, getEmail } from "../validations";
import jwt from "jwt-decode";

const stripCPFMask = (cpfValue) => {
  return cpfValue
    .replaceAll("_", "")
    .replaceAll(".", "")
    .replaceAll("-", "");
}

function BasicData1({ id, changeState, states, isCollaborator, disabled }) {
  const [indication, setIndicationForm] = useState('Não')

  let showDiamondCard = false
  try {
    const { is_user_and_customer, is_customer } = jwt(localStorage.getItem("token"));
    showDiamondCard = !is_customer || (is_user_and_customer && is_customer)
  } catch (e) { }

  return (
    <>
      <Row>
        <InputCPF
          colProps={{ sm: 12, md: 4 }}
          name="cpf"
          label="CPF"
          required="O campo não deve ser vazio e deve conter um CPF válido."
          disabled={id !== undefined}
          asyncValidation={
            id ? null : async (cpf) => {
              const errorMessage = await isCPFValid(cpf);
              changeState({ isValidCPF: !errorMessage });
              return errorMessage;
            }
          }
          onChange={async (event, cpfValue, ffHandler) => {
            ffHandler.setValue("email", "");
            ffHandler.setValue("fullname", "");
          }}
          onBlur={async (event, cpfValue, ffHandler) => {
            if (stripCPFMask(cpfValue).length === 0) {
              return
            }
            const isCollaborator = await getEmail(
              cpfValue,
              ffHandler,
              "cpf",
              states
            );
            changeState({ isCollaborator });
          }}
        />
        <InputEmail
          colProps={{ sm: 12, md: 4 }}
          name="email"
          label="Email"
          required="O campo não deve ser vazio e deve conter um e-mail válido."
          maxLength={200}
          disabled={isCollaborator}
          asyncValidation={async (email, ffHandler) => {
            const confirmEmail = ffHandler.getValue("confirmEmail")
            if (confirmEmail) {
              return confirmEmail === email ? null : "Os e-mails devem ser iguais"
            }

            if (!id || ffHandler.getActualValue("email") !== email) {
              const errorMessage = await isEmailValid(email, ffHandler);
              changeState({ isValidEmail: !errorMessage });
              return errorMessage;
            }
            return null;
          }}
        />
        <InputEmail
          colProps={{ sm: 12, md: 4 }}
          label="Confirmar e-mail"
          name="confirmEmail"
          maxLength={200}
          required='O campo "Confirmar e-mail" deve ser preenchido e igual ao campo "E-mail".'
          disabled={disabled}
          asyncValidation={(value, ffHandler) => {
            const isValid = ffHandler.getValue("email") === value;
            return isValid ? null : "Os e-mails devem ser iguais.";
          }}
          onBlur={(event, value, ffHandler) => {
            if (ffHandler.getValue("confirmEmail")) {
              ffHandler.testFieldError("email", true)
            }
          }}
        />
      </Row>

      {showDiamondCard && (
        <div style={{ background: '#dddddd7d', padding: '10px', borderRadius: '5px' }}>
          <b>Indicação</b>
          <Row>
            <RadioGroup
              colProps={{ sm: 12, md: 4 }}
              label="Cliente Indicação?"
              name="indication"
              options={[{ value: 'Não', label: "Não" }, { value: 'Indicador CNPJ', label: "Indicador CNPJ" }, { value: 'Cliente Diamante', label: "Cliente Diamante" }]}
              required='O campo "Cliente Indicação?" é obrigatório.'
              inline
              value={indication}
              onChange={(event, value) => {
                setIndicationForm(value)
              }}
            />

            {indication !== 'Não' && (
              <InputName
                colProps={{ xs: 12, sm: 7, md: 8, lg: 4 }}
                label="Nome"
                name="indicatorName"
                maxLength={100}
                required="Campo obrigatório"
              />
            )}
            {indication === 'Cliente Diamante' && (
              <InputCPF
                colProps={{ sm: 12, md: 4 }}
                name="indicatorDoc"
                label="CPF"
                required="O campo não deve ser vazio e deve conter um CPF válido."
                asyncValidation={
                  id ? null : async (cpf) => {
                    const errorMessage = await isCPFValid(cpf);
                    return errorMessage;
                  }
                }
                onBlur={async (event, cpfValue, ffHandler) => {
                  if (stripCPFMask(cpfValue).length === 0) {
                    return
                  }
                }}
              />
            )}
            {indication === 'Indicador CNPJ' && (
              <InputCNPJ
                colProps={{ sm: 12, md: 4 }}
                name="indicatorDoc"
                label="CNPJ"
                required="O campo não deve ser vazio e deve conter um CPF válido."
                asyncValidation={
                  id ? null : async (cnpj) => {
                    const errorMessage = (cnpj).replaceAll(/[^0-9]/g, '').length > 0 ? '' : 'Preencha o campo CNPJ'
                    return errorMessage;
                  }
                }
                onBlur={async (event, cnpj, ffHandler) => {
                  if ((cnpj).replaceAll(/[^0-9]/g, '').length === 0) {
                    return
                  }
                }}
              />
            )}
          </Row>
        </div>
      )}
    </>
  );
}

export default React.memo(BasicData1);
