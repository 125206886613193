import React, { Component } from "react";
import {
  Button,
  Col,
  Form,
  // InputGroup,
  Modal,
  Row,
  Table,
  // Tabs,
  // Tab,
} from "react-bootstrap";
import {
  getMissingPrivileges,
  insertSinglePrivilege,
} from "../../../services/RequestService";
import { alertActions } from "../../../store/actions/AlertActions";
import { connect } from "react-redux";

class ModalInsertSinglePrivilege extends Component {
  constructor(props) {
    super(props);

    this.state = {
      missingPrivileges: [],
      privilege: null,
    };
  }

  componentDidMount = () => {
    getMissingPrivileges({ cellId: this.props.cellId }).then((res) => {
      if (res.ok) {
        if (this.props.from === "cell") {
          let missingPrivileges = res.data.filter(
            (e) => e.privilege.name !== "configuracoes/celulas"
            // && e.privilege.name !== "configuracoes/grupo_de_trabalho" &&
            // e.privilege.name !== "configuracoes/parceiros"
          );

          this.setState({ missingPrivileges });
        } else {
          this.setState({
            missingPrivileges: res.data.filter(
              (item) => item.privilege.name !== "configuracoes/celulas"
            ),
          });
        }
      }
    });
  };

  handleInputChange = (e) => {
    e.persist();
    this.state.missingPrivileges.forEach((element) => {
      if (element.privilege.name === e.target.value) {
        this.setState({
          privilege: {
            name: element.privilege.name,
            privilegeId: element.privilege.id,
            insert: element.insert,
            read: element.read,
            update: element.update,
            delete: element.delete,
          },
        });
      }
    });
  };

  submit = () => {
    const { privilege } = this.state;
    insertSinglePrivilege({
      privilegeId: privilege.privilegeId,
      cellId: this.props.cellId,
      insert: privilege.insert !== null ? privilege.insert : false,
      read: privilege.read !== null ? privilege.read : false,
      update: privilege.update !== null ? privilege.update : false,
      delete: privilege.delete !== null ? privilege.delete : false,
    }).then((res) => {
      if (res.ok) {
        this.props.onAddAlert(
          "Privilégio adicionado com sucesso",
          "success",
          false
        );
        this.props.onHide(true);
      } else {
        this.props.onAddAlert(
          "Houve um problema ao tentar adicionar privilégio.",
          "danger",
          false
        );
      }
    });
  };

  render() {
    const {
      show,
      onHide,
      //  cellId
    } = this.props;
    const { missingPrivileges, privilege } = this.state;
    return (
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Adicionar um novo privilégio</Modal.Title>
        </Modal.Header>
        <Modal.Body className="align-self-center text-center">
          <Row>
            <Col>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Privilégios faltantes</Form.Label>
                <Form.Control
                  as="select"
                  value={privilege?.name}
                  onChange={this.handleInputChange}
                >
                  <option />
                  {missingPrivileges?.map((value) => {
                    return (
                      <option key={value.privilege.id}>
                        {" "}
                        {value.privilege.name}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Table
            striped
            bordered
            hover
            className="table cell-table-responsible bms-color-text "
            responsive={true}
          >
            <thead className={"text-center"}>
              <tr>
                <th>Nome</th>
                <th>Criar</th>
                <th>Ver</th>
                <th>Editar</th>
                <th>Excluir</th>
              </tr>
            </thead>
            <tbody className={"text-center"}>
              <tr>
                <td>{privilege?.name}</td>
                <td>
                  {privilege && (
                    <Form.Check
                      checked={privilege.insert}
                      onClick={() =>
                        this.setState({
                          privilege: {
                            ...privilege,
                            insert: !privilege["insert"],
                          },
                        })
                      }
                      disabled={privilege["insert"] === null}
                    />
                  )}
                </td>
                <td>
                  {privilege && (
                    <Form.Check
                      checked={privilege.read}
                      onClick={() =>
                        this.setState({
                          privilege: { ...privilege, read: !privilege["read"] },
                        })
                      }
                      disabled={privilege["read"] === null}
                    />
                  )}
                </td>
                <td>
                  {privilege && (
                    <Form.Check
                      checked={privilege.update}
                      onClick={() =>
                        this.setState({
                          privilege: {
                            ...privilege,
                            update: !privilege["update"],
                          },
                        })
                      }
                      disabled={privilege["update"] === null}
                    />
                  )}
                </td>
                <td>
                  {privilege && (
                    <Form.Check
                      checked={privilege.delete}
                      onClick={() =>
                        this.setState({
                          privilege: {
                            ...privilege,
                            delete: !privilege["delete"],
                          },
                        })
                      }
                      disabled={privilege["delete"] === null}
                    />
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="w-100 modal-user-button-without-rounded"
            onClick={() => {
              this.submit();
            }}
            disabled={!privilege}
          >
            {"Adicionar"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAddAlert: (text, alertType, alertPriority) =>
      dispatch(alertActions.addAlert(text, alertType, alertPriority)),
  };
};

export default connect(null, mapDispatchToProps)(ModalInsertSinglePrivilege);
