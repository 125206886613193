import React, { Component } from "react";
import { Row, Col, Container, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./NavigationComp.css";
import NavigationItemComp from "./NavigationItemComp";
import logoSmall from "../../res/LogoQualitteWhite.png";
import { getCurrentProcessByUserId, getInDebug, } from "../../services/RequestService";
import { Button } from "react-bootstrap";
import { setView, isCustomerView, getView } from "../../utils/storage";
import fn from "../../utils/functions"

class NavigationComp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inDebug: false,
      menuVisible: "",
      is_customer: this.setInitialView(),
      isSetting:
        props.selectedPath === "/configuracoes/usuarios" ||
        props.selectedPath === "/configuracoes/celulas" ||
        props.selectedPath === "/configuracoes/permissoes" ||
        props.selectedPath === "/configuracoes/processos" ||
        props.selectedPath === "/configuracoes/parceiros" ||
        props.selectedPath === "/configuracoes/andamentos" ||
        props.selectedPath === "/configuracoes/grupo_de_trabalho",
    };
  }

  componentDidMount = () => {
    getInDebug().then((res) => {
      if (res.ok) {
        this.setState({ inDebug: res.data });
      }
    });
    if (this.props.decodedToken.is_customer) {
      getCurrentProcessByUserId().then((res) => {
        if (res.ok) {
          this.setState((state) => ({ ...state, processId: res.data }));
        }
      });
    }
  };

  setInitialView = () => {
    if (getView()) {
      return isCustomerView();
    } else if (this.props.decodedToken.is_user_and_customer) {
      return false;
    }
    return this.props.decodedToken.is_customer;
  };

  openMenu = () => {
    this.setState({ menuVisible: "visible" });
  };

  closeMenu = () => {
    this.setState({ menuVisible: "hidden" });
  };

  toggleMenu = () => {
    this.setState((state) => ({
      menuVisible: state.menuVisible === "visible" ? "hidden" : "visible",
    }));
  };

  handleChangeView = () => {
    const is_customer = !this.state.is_customer;
    const isSetting = false;
    setView(is_customer);
    this.setState({ is_customer, isSetting });
  };

  renderLink = (itemName, path, iconClassName) => {
    return this.props.selectedPath === path ? (
      <div className="navi-td navi-selected">
        <NavigationItemComp icon={iconClassName} itemName={itemName} />
      </div>
    ) : (
      <div className="navi-td">
        <Link to={path} className="navi-unselected">
          <NavigationItemComp icon={iconClassName} itemName={itemName} />
        </Link>
      </div>
    );
  };

  render() {
    const { children, decodedToken } = this.props;
    const { inDebug, isSetting, processId, menuVisible, is_customer } =
      this.state;
    const is_user_and_customer = decodedToken?.is_user_and_customer;
    const is_admin = decodedToken?.is_admin;

    const link = fn.isMobileDevice() ? 'https://api.whatsapp.com/send/?phone=5508004540606' : 'https://web.whatsapp.com/send?phone=+5508004540606'
    return (
      <Container fluid={true}>
        <Button variant="primary" className="menu-btn-container menu-btn header" onClick={this.toggleMenu}><i className="fas fa-bars" /></Button>
        <Row xs>
          <div className={`navi-sidebar-background ${menuVisible}`} onClick={this.closeMenu} />
          <div className={`navi-sidebar ${menuVisible}`} onClick={(e) => e.stopPropagation()} >
            <div className="navi-sidebar-header">
              <Col xs className="p-0 d-flex navi-sidebar-header-image-container px-3">
                <Link to="/">
                  <Image className="navi-sidebar-header-image on-load-image" src={logoSmall} onLoad={(event) => {
                    event.target.style.opacity = 1;
                  }} />
                </Link>
                {inDebug && <h6 className="danger-color">Modo Debug ativo</h6>}
              </Col>
              <Col xs="auto" className="menu-btn-container">
                <Button variant="primary" className="menu-btn" onClick={this.closeMenu}><i className="fas fa-times" /></Button>
              </Col>
            </div>{" "}
            {is_user_and_customer && (
              <div className="navi-sidebar-view-button">
                <Link to="/">
                  <Button onClick={this.handleChangeView}>
                    <i className="fas fa-random pr-3" />
                    {"Ver Área "} {is_customer ? "Colaborador" : "Cliente"}
                  </Button>
                </Link>
              </div>
            )}
            {/* General Panel */}
            {(!is_customer && is_admin) && this.renderLink("Painel Geral", "/painel_geral", "fas fa-chart-bar")}
            {/* Calendar */}
            {(!is_customer && this.props.privileges?.agenda?.read) && this.renderLink("Agenda", "/agenda", "fas fa-calendar-alt")}
            {/* Process */}
            {(!is_customer && this.props.privileges?.processo?.read) && this.renderLink("Processos", "/processos", "fas fa-project-diagram")}
            {/* Costumer Process */}
            {is_customer && this.renderLink("Meus Processos", `/processo/${processId}/agenda`, "fas fa-project-diagram")}
            {/* Costumer Documents */}
            {is_customer && this.renderLink("Meus Documentos", `/processo/${processId}/documentos`, "fas fa-folder")}
            {/* Costumer data */}
            {is_customer && this.renderLink("Meus Dados", `/meus_dados/processo/${this.props.decodedToken.user_id}`, "fas fa-user")}
            {/* Customer */}
            {(!is_customer && this.props.privileges?.clientes?.read) && this.renderLink("Clientes", `/clientes`, "fas fa-users")}
            {/* Financeiro */}
            {(!is_customer && this.props.privileges?.financeiro?.read) && this.renderLink("Financeiro", `/financeiro`, "fas fa-coins")}
            {/* Relatório */}
            {(!is_customer && this.props.privileges?.relatorios?.read) && this.renderLink("Relatórios", `/relatorio`, "fas fa-file-alt")}
            {is_admin && this.renderLink("Comissão", `/comissao`, "fas fa-file-alt")}
            {/* Chat */}
            <div className="navi-td"><a className="navi-unselected" target="_blank" rel="noopener noreferrer" href={link}><div className="py-3 pl-4"><i className="fas fa-comments navi-item-icon"></i>Fale Conosco</div></a></div>
            {is_admin && this.renderLink("Enviar Mensagens", `/enviar-mensagens`, 'fab fa-whatsapp')}
            {/* Configurações */}
            {!is_customer && (this.props.privileges?.["configuracoes/andamentos"]?.read || this.props.privileges?.["configuracoes/celulas"]?.read ||
              this.props.privileges?.["configuracoes/grupo_de_trabalho"]?.read || this.props.privileges?.["configuracoes/usuarios"]?.read ||
              this.props.privileges?.["configuracoes/processos"]?.read || this.props.privileges?.["configuracoes/parceiros"]?.read) && (
                <div className="navi-td navi-unselected" onClick={() => this.setState({ isSetting: !isSetting, })}>
                  <NavigationItemComp icon="fas fa-cog" itemName="Configurações" children={
                    <i className={`fas fa-angle-down pl-2 navi-rotate-icon ${isSetting ? "" : "navi-icon-right"}`} />
                  } />
                </div>
              )}
            {isSetting && (
              <div>
                {/* Andamentos */}
                {(!is_customer && this.props.privileges?.["configuracoes/andamentos"]?.read) && this.renderLink("Andamentos", `/configuracoes/andamentos`)}
                {/* celulas */}
                {(!is_customer && this.props.privileges?.["configuracoes/celulas"]?.read) && this.renderLink("Células", `/configuracoes/celulas`)}
                {/* grupo de trabalho*/}
                {(!is_customer && this.props.privileges?.["configuracoes/grupo_de_trabalho"]?.read) && this.renderLink("Grupo de trabalho", `/configuracoes/grupo_de_trabalho`)}
                {/* Roles */}
                {this.props.privileges?.["configuracoes/usuarios"]?.read && this.renderLink("Usuários", `/configuracoes/usuarios`)}
                {/* Processos */}
                {this.props.privileges?.["configuracoes/processos"]?.read && this.renderLink("Processos", `/configuracoes/processos`)}
                {/* Partner */}
                {this.props.privileges?.["configuracoes/parceiros"]?.read && this.renderLink("Parceiros", `/configuracoes/parceiros`)}
                {is_admin && this.renderLink("Comissão", `/configuracoes/comissao-usuario`)}
                {is_admin && this.renderLink("Wide Chat", `/configuracoes/wide-chat`)}
                {is_admin && this.renderLink("Gerador Documento", `/configuracoes/gerador-documento`)}
              </div>
            )}
            {/* Sair */}
            <div className="navi-td">
              <Link to="/" className="navi-unselected" onClick={() => localStorage.clear()}>
                <NavigationItemComp icon="fas fa-door-open" itemName={"Sair"} />
              </Link>
            </div>
          </div>
          <Col xs className="navi-rest">{children}</Col>
        </Row>
      </Container>
    );
  }
}

export default NavigationComp;
