import React from "react";
import { Card, Col, Form, Tooltip, OverlayTrigger } from "react-bootstrap";

function RenderInput({
  name,
  label,
  value,
  disabled,
  colProps,
  options,
  ...props
}) {
  if (value || value === 0) {
    return (
      <OverlayTrigger
        placement={"bottom"}
        overlay={<Tooltip id={name}>{value}</Tooltip>}
      >
        <Col {...colProps}>
          <div className={`div-form-group ${disabled ? "" : "enabled"}`}>
            <Form.Group>
              <Form.Label>{label}</Form.Label>
              <Form.Control
                {...props}
                disabled={disabled}
                name={name}
                value={value}
                size="sm"
                as={options ? "select" : "input"}
                className="text-truncate"
              >
                {options ? (
                  <>
                    <option />
                    {options.map(({ value, label }, key) => (
                      <option key={key} value={value}>
                        {label}
                      </option>
                    ))}
                  </>
                ) : null}
              </Form.Control>
            </Form.Group>
          </div>
        </Col>
      </OverlayTrigger>
    );
  }

  return (
    <Col {...colProps}>
      <div className={`div-form-group ${disabled ? "" : "enabled"}`}>
        <Form.Group>
          <Form.Label>{label}</Form.Label>
          <Form.Control
            {...props}
            disabled={disabled}
            name={name}
            value={value}
            size="sm"
            className="text-truncate"
            as={options ? "select" : "input"}
          >
            {options ? (
              <>
                <option />
                {options.map(({ value, label }, key) => (
                  <option key={key} value={value}>
                    {label}
                  </option>
                ))}
              </>
            ) : null}
          </Form.Control>
        </Form.Group>
      </div>
    </Col>
  );
}

function Filter({
  isChangingFilter,
  allCells,
  cellHierarchy,
  allUsers,
  allProcessType,
  allProcessStatus,
  allProcessActionType,
  allProcessScenario,

  filterList,
  filterType,
  customerName,
  cellId,
  childCellId,
  userId,
  processTypeId,
  processStatusId,
  processActionId,
  processScenarioId,
  finished,
  done,

  startDate,
  endDate,
  startDatePP,
  endDatePP,
  doneDate,

  handleChangeFilterType,
  handleChangeFilter,
  handleChangeDateFilter,
}) {
  const [contentVisible, setContentVisible] = React.useState(true);
  const showUserName = false;
  const showProcessTypeId =
    filterType === "Relatório de vendas" ||
    filterType === "Relatório de propostas enviadas" ||
    filterType === "Relatório de propostas aprovadas" ||
    filterType === "Relatório de contratos assinados" ||
    filterType === "Agenda dos Usuários" ||
    filterType === "Prazos Suspensos, Processos e Justificativas" ||
    filterType === "Relatório de contratos enviados" ||
    filterType === "Relatório de Status dos Processos";
  const showProcessStatusId =
    filterType === "Relatório de Status dos Processos";
  const showWorkGroup =
    filterType === "Relatório de vendas" ||
    filterType === "Relatório de propostas enviadas" ||
    filterType === "Relatório de propostas aprovadas" ||
    filterType === "Relatório de contratos assinados" ||
    filterType === "Relatório de recebimentos" ||
    filterType === "Relatório de pagamento credenciado" ||
    filterType === "Relatório de contratos enviados" ||
    filterType === "Relatório de Processos Fechados" ||
    filterType === "Relatório de cadastro de credenciados";

  const showChildWorkGroups =
    showWorkGroup &&
    cellId !== "" &&
    Object.keys(cellHierarchy[cellId].children).length > 0;

  const showUsers =
    filterType === "Relatório de propostas enviadas" ||
    filterType === "Relatório de propostas aprovadas" ||
    filterType === "Relatório de contratos assinados" ||
    filterType === "Relatório de recebimentos" ||
    filterType === "Relatório de contratos enviados" ||
    filterType === "Relatório de cadastro de credenciados" ||
    filterType === "Agenda dos Usuários";
  const showStartDate =
    filterType === "Relatório de vendas" ||
    filterType === "Relatório de propostas enviadas" ||
    filterType === "Relatório de propostas aprovadas" ||
    filterType === "Relatório de contratos enviados" ||
    filterType === "Relatório de contratos assinados" ||
    filterType === "Relatório de recebimentos" ||
    filterType === "Relatório de Status dos Processos" ||
    filterType === "Relatório de cadastro de credenciados" ||
    filterType === "Relatório de cadastro de usuários" ||
    filterType === "Relatório de Processos Fechados" ||
    filterType === "Agenda dos Usuários" ||
    filterType === "Prazos Suspensos, Processos e Justificativas";
  const showEndDate =
    filterType === "Relatório de vendas" ||
    filterType === "Relatório de propostas enviadas" ||
    filterType === "Relatório de propostas aprovadas" ||
    filterType === "Relatório de contratos enviados" ||
    filterType === "Relatório de contratos assinados" ||
    filterType === "Relatório de recebimentos" ||
    filterType === "Relatório de Status dos Processos" ||
    filterType === "Relatório de cadastro de credenciados" ||
    filterType === "Relatório de cadastro de usuários" ||
    filterType === "Relatório de Processos Fechados" ||
    filterType === "Agenda dos Usuários" ||
    filterType === "Prazos Suspensos, Processos e Justificativas";
  const showStartDatePP =
    filterType === "Relatório de Acompanhamento do Radar Previdenciário";
  const showEndDatePP =
    filterType === "Relatório de Acompanhamento do Radar Previdenciário";
  const showDate = filterType === "Relatório de pagamento credenciado";
  const showActionTypeId = filterType === "Agenda dos Usuários";
  const showFinished = filterType === "Agenda dos Usuários";
  const showScenario =
    filterType === "Relatório de Acompanhamento do Radar Previdenciário";

  return (
    <Card>
      <div className="p-3">
        <div
          className={`clickable pt-3 mt-n3 px-3 mx-n3 d-flex justify-content-between header-card-hover ${contentVisible ? "pb-2" : "mb-n3 pb-3"
            }`}
          onClick={() => setContentVisible(!contentVisible)}
        >
          <span>
            <strong>Filtros</strong>
          </span>
          <i
            className={`fas fa-angle-down pl-2 navi-rotate-icon ${contentVisible ? "" : "navi-icon-right"
              }`}
          />
        </div>
        {contentVisible ? (
          <div>
            <div className={"mb-2 border-top"} />
            <Form.Row>
              <RenderInput
                colProps={{ xs: "12", sm: "12", md: "6" }}
                name="filterType"
                label="FATO GERADOR"
                value={filterType}
                onChange={handleChangeFilterType}
                disabled={!isChangingFilter}
                options={
                  filterList
                    ? filterList.map((item) => ({
                      value: item,
                      label: item,
                    }))
                    : null
                }
              />
              {showStartDate && (
                <Col {...{ xs: "6", sm: "6", md: "3" }}>
                  <div
                    className={`div-form-group ${!isChangingFilter ? "" : "enabled"
                      }`}
                  >
                    <Form.Group>
                      <Form.Label>
                        {filterType === "Relatório de pagamento credenciado"
                          ? "DATA"
                          : filterType ===
                            "Prazos Suspensos, Processos e Justificativas"
                            ? "DATA INICIAL DA PAUSA"
                            : "DATA INICIAL"}
                      </Form.Label>
                      <Form.Control
                        value={startDate}
                        onChange={handleChangeFilter}
                        disabled={!isChangingFilter}
                        type={
                          filterType === "Relatório de pagamento credenciado"
                            ? "month"
                            : "date"
                        }
                        name="startDate"
                        size="sm"
                        className="text-truncate"
                      />
                    </Form.Group>
                  </div>
                </Col>
              )}
              {showDate && (
                <Col {...{ xs: "6", sm: "6", md: "3" }}>
                  <div
                    className={`div-form-group ${!isChangingFilter ? "" : "enabled"
                      }`}
                  >
                    <Form.Group>
                      <Form.Label>DATA</Form.Label>
                      <Form.Control
                        value={startDate}
                        onChange={handleChangeFilter}
                        disabled={!isChangingFilter}
                        type="month"
                        name="startDate"
                        size="sm"
                        className="text-truncate"
                      />
                    </Form.Group>
                  </div>
                </Col>
              )}
              {showEndDate && (
                <Col {...{ xs: "6", sm: "6", md: "3" }}>
                  <div
                    className={`div-form-group ${!isChangingFilter ? "" : "enabled"
                      }`}
                  >
                    <Form.Group>
                      <Form.Label>
                        {filterType ===
                          "Prazos Suspensos, Processos e Justificativas"
                          ? "DATA FINAL DA PAUSA"
                          : "DATA FINAL"}
                      </Form.Label>
                      <Form.Control
                        value={endDate}
                        onChange={handleChangeFilter}
                        disabled={!isChangingFilter}
                        type="date"
                        name="endDate"
                        size="sm"
                        className="text-truncate"
                      />
                    </Form.Group>
                  </div>
                </Col>
              )}
              {showStartDatePP && (
                <Col {...{ xs: "6", sm: "6", md: "3" }}>
                  <div
                    className={`div-form-group ${!isChangingFilter ? "" : "enabled"
                      }`}
                  >
                    <Form.Group>
                      <Form.Label>
                        {"PLANEJAMENTO ENCERRADO DEPOIS DE"}
                      </Form.Label>
                      <Form.Control
                        value={startDatePP}
                        onChange={handleChangeFilter}
                        disabled={!isChangingFilter}
                        type="date"
                        name="startDatePP"
                        size="sm"
                        className="text-truncate"
                      />
                    </Form.Group>
                  </div>
                </Col>
              )}
              {showEndDatePP && (
                <Col {...{ xs: "6", sm: "6", md: "3" }}>
                  <div
                    className={`div-form-group ${!isChangingFilter ? "" : "enabled"
                      }`}
                  >
                    <Form.Group>
                      <Form.Label>
                        {"PLANEJAMENTO ENCERRADO ANTES DE"}
                      </Form.Label>
                      <Form.Control
                        value={endDatePP}
                        onChange={handleChangeFilter}
                        disabled={!isChangingFilter}
                        type="date"
                        name="endDatePP"
                        size="sm"
                        className="text-truncate"
                      />
                    </Form.Group>
                  </div>
                </Col>
              )}
              {showUserName && (
                <RenderInput
                  colProps={{ xs: "12", sm: "6", md: "5" }}
                  name="customerName"
                  label="NOME DO CLIENTE"
                  value={customerName}
                  type="text"
                  disabled={!isChangingFilter}
                  onChange={handleChangeFilter}
                />
              )}
              {showProcessTypeId && (
                <RenderInput
                  colProps={{ xs: "12", md: "6" }}
                  name="processTypeId"
                  label="TESE"
                  value={processTypeId}
                  onChange={handleChangeFilter}
                  disabled={!isChangingFilter}
                  options={
                    allProcessType
                      ? Object.entries(allProcessType).map((value) => ({
                        label: value[0],
                        value: value[1],
                      }))
                      : null
                  }
                />
              )}
              {showProcessStatusId && (
                <RenderInput
                  colProps={{ xs: "12", md: "6" }}
                  name="processStatusId"
                  label="ANDAMENTO DO PROCESSO"
                  value={processStatusId}
                  onChange={handleChangeFilter}
                  disabled={!isChangingFilter}
                  options={
                    allProcessStatus
                      ? Object.entries(allProcessStatus).map((value) => ({
                        label: value[0],
                        value: value[1],
                      }))
                      : null
                  }
                />
              )}
              {showActionTypeId && (
                <RenderInput
                  colProps={{ xs: "12", md: "6" }}
                  name="processActionId"
                  label="TIPO DE ANDAMENTO"
                  value={processActionId}
                  onChange={handleChangeFilter}
                  disabled={!isChangingFilter}
                  options={
                    allProcessActionType
                      ? Object.entries(allProcessActionType).map((value) => ({
                        label: value[0],
                        value: value[1],
                      }))
                      : null
                  }
                />
              )}
              {showWorkGroup && (
                <RenderInput
                  colProps={{ xs: "12", md: "6" }}
                  name="cellId"
                  label="GRUPO DE TRABALHO"
                  value={cellId}
                  onChange={handleChangeFilter}
                  disabled={!isChangingFilter}
                  options={
                    allCells
                      ? Object.entries(allCells).map((value) => ({
                        label: value[0],
                        value: value[1],
                      }))
                      : null
                  }
                />
              )}
              {showChildWorkGroups && (
                <RenderInput
                  colProps={{ xs: "12", md: "6" }}
                  name="childCellId"
                  label="CÉLULA FILHA"
                  value={childCellId}
                  onChange={handleChangeFilter}
                  disabled={!isChangingFilter}
                  options={
                    cellHierarchy && cellId !== ""
                      ? Object.values(cellHierarchy[cellId].children).map(
                        (value) => {
                          console.log(value);
                          return {
                            label: value.name,
                            value: value.id,
                          };
                        }
                      )
                      : null
                  }
                />
              )}
              {showUsers && (
                <RenderInput
                  colProps={{ xs: "12", md: "6" }}
                  name="userId"
                  label="USUÁRIO"
                  value={userId}
                  onChange={handleChangeFilter}
                  disabled={!isChangingFilter}
                  options={
                    allUsers
                      ? allUsers.map((value) => ({
                        label: value.name,
                        value: value.id,
                      }))
                      : null
                  }
                />
              )}
              {showScenario && (
                <RenderInput
                  colProps={{ xs: "12", md: "6" }}
                  name="processScenarioId"
                  label="CENÁRIO"
                  value={processScenarioId}
                  onChange={handleChangeFilter}
                  disabled={!isChangingFilter}
                  options={
                    allProcessScenario
                      ? Object.entries(allProcessScenario).map((value) => ({
                        label: value[0],
                        value: value[1],
                      }))
                      : null
                  }
                />
              )}
              {showFinished && (
                <RenderInput
                  colProps={{ xs: "12", md: "6" }}
                  name="finished"
                  label="Concluído "
                  value={finished}
                  onChange={handleChangeFilter}
                  disabled={!isChangingFilter}
                  options={[
                    { label: "Sim", value: true },
                    { label: "Não", value: false },
                  ]}
                />
              )}
            </Form.Row>
          </div>
        ) : null}
      </div>
    </Card>
  );
}

export default Filter;
