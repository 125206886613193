import React from "react";
import { Row } from "react-bootstrap";
import { InputPassword, RadioGroup } from "../../../genericComponents";

function CustomerSurvey13({ serverData }) {
  const [inssPassword, setInssPassword] = React.useState(false);
  const inss = serverData?.inss;
  React.useEffect(() => {
    if (inss === "Sim") {
      setInssPassword(true);
    }
  }, [inss, setInssPassword]);

  return (
    <Row>
      <RadioGroup
        colProps={{ xs: 12 }}
        label="Você quer informar sua senha do MEU INSS agora?"
        name="inss"
        options={["Sim", "Não"].map((item) => ({
          label: item,
          value: item,
        }))}
        required='O campo "Você quer informar sua senha do MEU INSS agora?" é obrigatório.'
        inline
        onChange={(event, value) => {
          setInssPassword(value === "Sim");
        }}
      />
      {inssPassword ? (
        <InputPassword
          colProps={{ xs: 12 }}
          label="Senha do INSS"
          name="inssPassword"
          required='O campo "Senha do INSS" é obrigatório.'
          inputMaskProps={{
            placeholder: "Informe a sua senha do INSS",
          }}
          maxLength={20}
        />
      ) : null}
    </Row>
  );
}

export default React.memo(CustomerSurvey13);
