import React, { Component } from "react";
import "./ReportPage.css";
import { Card, Table } from "react-bootstrap";
import { connect } from "react-redux";
import { alertActions } from "../../store/actions/AlertActions";
import ModalReport from "../../components/report/ModalReport";
import ModalReportFilter from "../../components/report/ModalReportFilter";
import { Header, Loading } from "../../genericComponents";
import { getReports } from "./controllers/getReportControllers";
import Filter from "../report/presentations/Filter";
import {
  getAllActionType,
  getAllProcessStatus,
  getAllProcessType,
  getAllScenario,
  getCellFullHierarchy,
} from "../../services/RequestService";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import ReportTableFooter from "./components/ReportTableFooter";
class ReportPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allReports: [],
      headerTitles: [],
      allReportsProcess: [],
      showModalInsertReport: false,
      showModalFilterReport: false,
      sort: {
        id: null,
        name: null,
      },

      customerName: "",
      cellId: "",
      childCellId: "",
      processTypeId: "",
      processStatusId: "",
      processActionId: "",
      processScenarioId: "",
      done: false,
      startDate: "",
      endDate: "",
      startDatePP: "",
      endDatePP: "",
      doneDate: "",
      finished: false,

      filterList: [
        "Relatório de vendas",
        "Relatório de propostas enviadas",
        "Relatório de propostas aprovadas",
        "Relatório de contratos enviados",
        "Relatório de contratos assinados",
        "Relatório de recebimentos",
        "Relatório de pagamento credenciado",
        "Relatório de cadastro de credenciados",
        "Relatório de cadastro de usuários",
        "Relatório de Status dos Processos",
        "Relatório de Processos Fechados",
        "Agenda dos Usuários",
        "Prazos Suspensos, Processos e Justificativas"
      ],
      filterType: "Relatório de vendas",
      isChangingFilter: false,
      loading: true,
      cells: {},
      usersCells: {},
      users: {},
      processTypeList: {},
      processStatusList: {},
      processActionTypeList: {},
      processScenarioList: {},
      cellHierarchy: {},
      allUsers: [],
    };
    this.elementRef = React.createRef(null);
    this.callbacksProps = {
      handleChangeFilterType: this.handleChangeFilterType,
      handleChangeFilter: this.handleChangeFilter,
      handleChangeDateFilter: this.handleChangeDateFilter,
    };
  }

  componentDidMount = () => {
    getCellFullHierarchy().then((res) => {
      const cellHierarchy = {};
      const cells = {};
      let allUsers = [];
      if (res.ok) {
        res.data.forEach((item) => {
          cellHierarchy[item.id] = {
            id: item.id,
            children: item.children.reduce((total, currentChild) => {
              total[currentChild.id] = {
                id: currentChild.id,
                name: currentChild.name,
              };

              return total;
            }, {}),
            name: item.name,
            members: item.members,
          };
          cells[item.name] = item.id;
          allUsers = allUsers.concat(item.members);
        });

        this.setState((state) => ({
          ...state,
          cellHierarchy,
          cells,
          allUsers,
        }));
      }
    });
    getAllProcessType().then((res) => {
      if (res.ok) {
        let processTypeList = {};
        res.data.forEach((item) => {
          processTypeList[item.name] = item.id;
        });
        this.setState((state) => ({ ...state, processTypeList }));
      }
    });
    getAllProcessStatus().then((res) => {
      if (res.ok) {
        let processStatusList = {};
        res.data.forEach((item) => {
          processStatusList[item.name] = item.id;
        });
        this.setState((state) => ({ ...state, processStatusList }));
      }
    });
    this.setState({ loading: false });
    getAllActionType().then((res) => {
      if (res.ok) {
        let processActionTypeList = {};
        res.data.forEach((item) => {
          processActionTypeList[item.name] = item.id;
        });
        this.setState((state) => ({ ...state, processActionTypeList }));
      }
    });
    getAllScenario().then((res) => {
      if (res.ok) {
        let processScenarioList = {};
        res.data.forEach((item) => {
          processScenarioList[item.name] = item.id;
        });
        this.setState((state) => ({ ...state, processScenarioList }));
      }
    });
  };

  handleChangeFilterType = (e) => {
    e.persist();
    this.setState((state) => ({ ...state, filterType: e.target.value }));
  };

  handleChangeFilter = (e) => {
    e.persist();
    const { value, name } = e.target;
    if (name === "cellId") {
      const { usersCells } = this.state;
      const users = {};
      usersCells[value] &&
        usersCells[value].forEach((item) => {
          users[item.name] = item.id;
        });
      this.setState({ cellId: value, users, childCellId: "" });
    } else if (name === "childCellId") {
      const { usersCells } = this.state;
      const users = {};
      usersCells[value] &&
        usersCells[value].forEach((item) => {
          users[item.name] = item.id;
        });
      this.setState({ childCellId: value, users });
    } else if (name === "endDate") {
      console.log(value);
      this.setState((state) => ({
        ...state,
        [name]: value,
      }));
    } else {
      this.setState((state) => ({
        ...state,
        [name]: value,
      }));
    }
  };

  handleChangeDateFilter = (e) => {
    e.persist();
    let value = e.target.value;
    this.setState((state) => ({
      ...state,
      [e.target.name]: value,
    }));
  };

  setShowModalInsertReport = (showModalInsertReport, id, name, modalType) => {
    this.setState((state) => ({
      ...state,
      showModalInsertReport,
      id,
      name,
      modalType,
    }));
    if (!showModalInsertReport) {
      this.componentDidMount();
    }
  };

  setChangeFilter = (isChangingFilter) => {
    this.setState((state) => ({
      ...state,
      isChangingFilter,
    }));
    if (!isChangingFilter) {
      this.setFilter();
    }
  };

  formatDate = (date) => {
    date = date.split("T")[0].split("-");
    return date[2] + "/" + date[1] + "/" + date[0];
  };

  getAllReportsProcess = () => {
    const { allReportsProcess } = this.state;
    return allReportsProcess;
  };

  setFilter = () => {
    const {
      filterType,
      customerName,
      cellId,
      childCellId,
      userId,
      processTypeId,
      processStatusId,
      processActionId,
      processScenarioId,
      startDate,
      endDate,
      startDatePP,
      endDatePP,
      done,
      doneDate,
      finished,
    } = this.state;

    console.log("START DATE >>> ", startDate);

    const sd = startDate !== "" ? `${startDate}T00:01:00.000Z` : null;

    const ed = endDate !== "" ? `${endDate}T23:59:00.000Z` : null;

    const sdpp = startDatePP !== "" ? `${startDatePP}T00:01:00.000Z` : null;

    const edpp = endDatePP !== "" ? `${endDatePP}T23:59:00.000Z` : null;

    const filter = {
      customerName,
      cellId: childCellId === "" ? cellId : childCellId,
      userId,
      processTypeId,
      processStatusId,
      processActionId,
      processScenarioId,
      done,
      startDate: sd,
      endDate: ed,
      startDatePP: sdpp,
      endDatePP: edpp,
      doneDate,
      finished,
    };

    if (filterType === "Relatório de pagamento credenciado") {
      const [yearStr, monthStr] = startDate.split("-");

      console.log(yearStr);
      console.log(monthStr);

      filter["month"] = Number(monthStr);
      filter["year"] = Number(yearStr);
    }

    this.setState({ loading: true });
    getReports(
      filterType,
      filter,
      (body) => this.setState(body),
      this.props.onAddAlert
    );
  };

  getFile = () => {
    const { headerTitles, allReports, filterType } = this.state;

    let doc = new jsPDF("landscape");

    const tableContent = allReports.map((item) => Object.values(item));

    const linesTotal = allReports.length;

    autoTable(doc, {
      head: [headerTitles],
      body: tableContent,
      foot: [["Totais", `Registros: ${linesTotal}`]],
    });

    let reportName = "";

    reportName += filterType.toLowerCase().replaceAll(" ", "_");

    reportName += ".pdf";

    doc.save(reportName);
  };

  render() {
    const {
      loading,
      isChangingFilter,
      filterList,
      filterType,
      allReports,
      headerTitles,
      showModalInsertReport,
      id,
      name,
      modalType,
      cells,
      allUsers,
      processTypeList,
      processStatusList,
      processActionTypeList,
      processScenarioList,
      cellHierarchy,
      childCellId,
    } = this.state;
    const nullValue = null;

    console.log("CHILD CELL ID", childCellId);

    return (
      <>
        <Loading
          loading={loading}
          loadingText={!loading ? null : "Carregando..."}
        >
          <Header
            active="Relatórios"
            logged
            buttons={[
              {
                onClick: () => this.setChangeFilter(!isChangingFilter),
                icon: "fas fa-filter",
                variant: isChangingFilter ? "success" : "primary",
                label: isChangingFilter
                  ? "Atualizar Filtros"
                  : "Editar Filtros",
              },
              this.props.privileges?.relatorios?.create
                ? {
                  icon: "fas fa-file-pdf",
                  label: "Baixar PDF do Relatório",
                  onClick: () => this.getFile(),
                  disabled: isChangingFilter,
                }
                : null,
            ]}
          />
          <div className="single-process-body">
            <div className={"scrollContainer"}>
              <div className={"p-2"}>
                <Filter
                  isChangingFilter={isChangingFilter}
                  customerName={this.state.customerName}
                  cellId={this.state.cellId}
                  childCellId={this.state.childCellId}
                  userId={this.state.userId}
                  processTypeId={this.state.processTypeId}
                  processStatusId={this.state.processStatusId}
                  processActionId={this.state.processActionId}
                  processScenarioId={this.state.processScenarioId}
                  done={this.state.done}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  doneDate={this.state.doneDate}
                  filterList={filterList}
                  filterType={filterType}
                  allCells={cells}
                  allUsers={allUsers}
                  allProcessType={processTypeList}
                  allProcessStatus={processStatusList}
                  allProcessActionType={processActionTypeList}
                  allProcessScenario={processScenarioList}
                  cellHierarchy={cellHierarchy}
                  {...this.callbacksProps}
                ></Filter>
                <Card className="mt-4">
                  {allReports.length !== 0 ? (
                    <div className="p-1 m-3 report-table">
                      <Table
                        striped
                        bordered
                        hover
                        id="myTable"
                        className="table-bordered bms-color-text"
                        responsive={true}
                      >
                        <thead>
                          <tr>
                            {headerTitles?.map((element, key) => {
                              return <td key={key}>{element}</td>;
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {allReports.map((item, key) => {
                            return (
                              <tr key={key}>
                                {Object.entries(item)?.map((element, key) => {
                                  return <td key={key}>{element[1]}</td>;
                                })}
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  ) : (
                    <div className="p-3 text-center">
                      {"Não foi possivel carregar uma lista com este filtro"}
                    </div>
                  )}
                </Card>
                {allReports.length !== 0 ? (
                  <Card>
                    <ReportTableFooter
                      tableData={allReports}
                      filterType={filterType}
                    />
                  </Card>
                ) : null}
              </div>
            </div>
          </div>
          {showModalInsertReport && (
            <ModalReport
              show={showModalInsertReport}
              onHide={() =>
                this.setShowModalInsertReport(
                  false,
                  nullValue,
                  nullValue,
                  nullValue
                )
              }
              id={id}
              name={name}
              modalType={modalType}
            />
          )}
          {true && (
            <ModalReportFilter
              show={false}
              onHide={() => this.setShowModalFilterReport(false)}
              setFilter={(filter) => this.setFilter(filter)}
            />
          )}
        </Loading>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAddAlert: (text, alertType, alertPriority) =>
      dispatch(alertActions.addAlert(text, alertType, alertPriority)),
  };
};

export default connect(null, mapDispatchToProps)(ReportPage);
