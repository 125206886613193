import React, { Component } from "react";
import "./CalendarPage.css";
import CardProcessComp from "../../components/calendar/card/CardProcessComp";
import CardActionComp from "../../components/calendar/card/CardActionComp";
import CardAlertsComp from "../../components/calendar/card/CardAlertsComp";
import { Header, Loading, PageContainer } from "../../genericComponents";

class CalendarPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingCounter: 3,
    };
  }

  addLoading = () => {
    this.setState((state) => ({ loadingCounter: (state.loadingCounter += 1) }));
    console.log(this.state.loadingCounter)
  };

  subtractLoading = () => {
    this.setState((state) => ({ loadingCounter: (state.loadingCounter = state.loadingCounter === 0 ? 0 : state.loadingCounter - 1) }));
    console.log(this.state.loadingCounter)
  };

  render() {
    const { loadingCounter } = this.state;
    return (
      <Loading loading={loadingCounter !== 0} hideChildrenWhileLoading>
        {/*Header Page*/}
        <Header active="Agenda" logged />
        <PageContainer row>
          {/* Cards process */}
          <CardProcessComp
            addLoading={this.addLoading}
            subtractLoading={this.subtractLoading}
          />
          {/* Cards action */}
          <CardActionComp
            addLoading={this.addLoading}
            subtractLoading={this.subtractLoading}
          />
          {/* Cards alerts */}
          <CardAlertsComp
            addLoading={this.addLoading}
            subtractLoading={this.subtractLoading}
          />
        </PageContainer>
      </Loading>
    );
  }
}

export default CalendarPage;
